'use client';

import React from 'react';

import ErrorComponent from '@Components/molecules/ErrorComponent/ErrorComponent';

const ErrorPage = ({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) => {
  return <ErrorComponent error={error} reset={reset} />;
};

export default ErrorPage;
