import React from 'react';

import { PropsWithClassName } from '@Components/helper';

import { Root } from './LinkButton.styles';

export enum LinkButtonModifier {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
}

interface Props extends PropsWithClassName {
  children: React.ReactNode;
  modifier?: LinkButtonModifier;
  url: string;
  target?: React.HTMLAttributeAnchorTarget;
  rel?: string;
}

const LinkButton = (props: Props): React.ReactElement => {
  const { url, children, modifier = LinkButtonModifier.Primary, rel, target } = props;

  return (
    <Root
      className={props.className}
      data-testid={props.dataTestId ? props.dataTestId : 'LinkButton-root'}
      href={url}
      modifier={modifier}
      target={target}
      rel={rel}
    >
      <span>{children}</span>
    </Root>
  );
};

export default LinkButton;
