import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex: 1;
  min-height: 100vh;
  padding: ${(props) => props.theme.space.xl_64} 0;
  background: ${(props) => props.theme.palette.background.mainGradient.start};
  background: linear-gradient(
    180deg,
    ${(props) => props.theme.palette.background.mainGradient.start} 0%,
    ${(props) => props.theme.palette.background.mainGradient.end} 100%
  );
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space.large_32};
  align-items: center;
`;
