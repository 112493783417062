import styled from 'styled-components';

import { LinkButtonModifier } from '@Components/atoms/LinkButton/LinkButton';

import Link from '@Helpers/i18n/Link';

interface LinkProps {
  modifier: LinkButtonModifier;
}

export const Root = styled(Link)<LinkProps>`
  cursor: pointer;
  display: inline-flex;
  position: relative;
  text-decoration: none;
  text-align: center;
  align-items: center;
  padding: 9px 17px 9px 17px;
  min-width: 6.25rem;
  min-height: 2.75rem;
  max-height: 2.75rem;

  background: ${(props) => props.theme.palette.button[props.modifier].background};
  border: 1px solid ${(props) => props.theme.palette.button[props.modifier].border};
  box-shadow: 0 1px 2px ${(props) => props.theme.palette.button.shadow};
  border-radius: ${(props) => props.theme.borderRadius.button};
  color: ${(props) => props.theme.palette.button[props.modifier].text};
  justify-content: center;

  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.semibold.small.fontSize};
  font-weight: ${(props) => props.theme.font.text.semibold.small.fontWeight};
  line-height: ${(props) => props.theme.font.text.semibold.small.lineHeight};
  letter-spacing: ${(props) => props.theme.font.text.semibold.small.letterSpacing};

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  &:hover {
    outline: 1px solid ${(props) => props.theme.palette.button[props.modifier].hoverBorder};
  }

  &:active {
    outline: 1px solid ${(props) => props.theme.palette.button[props.modifier].hoverBorder};
  }
`;
export const Icon = styled.div`
  position: relative;
  margin-right: ${(props) => props.theme.space.xs_4};
  width: 24px;
  height: 24px;
`;
