import React, { PropsWithChildren } from 'react';

import Button from '@Components/atoms/Button';
import { ButtonModifier, ButtonType } from '@Components/atoms/Button/Button';
import Container from '@Components/atoms/Container';
import Headline from '@Components/atoms/Headline';
import LinkButton, { LinkButtonModifier } from '@Components/atoms/LinkButton/LinkButton';
import Row from '@Components/atoms/Row';
import { RowGap } from '@Components/atoms/Row/Row';
import Text from '@Components/atoms/Text';
import { PropsWithClassName } from '@Components/helper';
import Logo from '@Components/molecules/Logo/Logo';

import { TextSize } from '@Helpers/types/text';

import { Column, Root } from './ErrorComponent.styles';

interface Props extends PropsWithClassName {
  error: Error & { digest?: string };
  reset: () => void;
}

const ErrorComponent: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>): React.ReactElement => {
  const { error, reset } = props;

  return (
    <Root className={props.className} data-testid={props.dataTestId ?? 'error-component-root'}>
      <Container>
        <Column>
          <Logo height={50} />
          <Headline importance={2}>Something went wrong!</Headline>
          <Text size={TextSize.Large}>
            {"We are sorry for the inconvenience. Don't worry, we've been reported about that."}
          </Text>
          <Text size={TextSize.Medium}>Reason: {error.message}</Text>
          <Row rowGap={RowGap.Default}>
            <Button modifier={ButtonModifier.Primary} type={ButtonType.Button} onClick={() => reset()}>
              Try again
            </Button>
            <LinkButton modifier={LinkButtonModifier.Secondary} url={'/'}>
              Go to Homepage
            </LinkButton>
          </Row>
        </Column>
      </Container>
    </Root>
  );
};

export default ErrorComponent;
